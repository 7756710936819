import styles from '../../styles/HeaderCarousel.module.scss';
import {
  PhotoContainer,
  HeaderButton,
  BackgroundWrapper,
} from './CarouselPhotoContainer.style';
import { Chip } from '@mui/material';

const CarouselPhotoContainer = ({ news, goToArticle }) => {
  return (
    <>
      <PhotoContainer key={'photoContainer-' + news.id} news={news}>
        <div className={styles.carouselTextWrapper}>
          <div className={styles.carouselTextContainer}>
            <Chip
              label={news.category === 'regular' ? 'Wydarzenia' : 'Sport'}
              className={`${styles.label} ${styles.labelBlue}`}
            />
            <p
              className={styles.carouselNewsHeader}
              onClick={() => goToArticle(news.id)}
            >
              {news.title}
            </p>
            <p
              className={styles.carouselNewsText}
              onClick={() => goToArticle(news.id)}
            >
              {news.content}
              <HeaderButton
                variant='text'
                onClick={() => goToArticle(news.id)}
                disableTouchRipple={true}
              >
                Więcej{'>'}
              </HeaderButton>
            </p>
          </div>
        </div>
      </PhotoContainer>
      <BackgroundWrapper image={news} />
    </>
  );
};

export default CarouselPhotoContainer;

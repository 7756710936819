import { useEffect, useState } from 'react';

import AboutUs from '../components/AboutUs/AboutUs';
import Ad from '../components/Ad/Ad';
import AddedBefore from '../components/AddedBefore/AddedBefore';
import ShowMoreButton from '../components/button/ShowMoreButton';
import HeaderCarousel from '../components/Carousel/HeaderCarousel/HeaderCarousel';
import Cooperate from '../components/Cooperate/Cooperate';
import LinkToMaterial from '../components/LinkToMaterial/LinkToMaterial';
import WatchNow from '../components/WatchNow/WatchNow';
import styles from '../styles/Home.module.scss';
import { useMobile } from '../utils/useMobile';

const Home = ({ data, videos, banersData }) => {
  const [iterator, setIterator] = useState(9);
  const dataForSlider = data.data.slice(0, 6);
  const dataForSideNews = data.data.slice(0, 6);
  const dataLeftColumn = data.data.slice(6, iterator);
  const dataRightColumn = data.data.slice(iterator, iterator + 3);
  const { isMobile, width } = useMobile();
  const homeBaners = banersData.data?.find((baner) => baner.site === 'home');
  const { baners, destination_urls } = homeBaners ?? {};
  const showMoreNews = () => {
    setIterator(iterator + 3);
  };

  return (
    <>
      <HeaderCarousel
        dataForSlider={dataForSlider}
        dataForSideNews={dataForSideNews}
      />
      <div className={styles.homeContainer}>
        <div className={styles.container}>
          <div className={styles.articles}>
            <p className={styles.articlesHeader}>Najnowsze</p>
            {dataLeftColumn.map((news, id) => {
              return (
                <div key={news + id}>
                  <LinkToMaterial
                    material={news}
                    key={'news-' + news.id}
                    variant={isMobile ? 'small' : 'big'}
                  />
                  {id == 1 &&
                    homeBaners &&
                    (isMobile ? (
                      <Ad
                        baner={baners[2]}
                        url={destination_urls[2]}
                        key={'baner-1'}
                      />
                    ) : (
                      <Ad
                        baner={baners[0]}
                        url={destination_urls[0]}
                        type={'wide'}
                        key={'baner-2'}
                      />
                    ))}
                </div>
              );
            })}
            {dataLeftColumn.length !== data.data.length && (
              <ShowMoreButton click={showMoreNews} />
            )}
          </div>
          <AddedBefore
            direction={'column'}
            articles={dataRightColumn}
            banersData={homeBaners && !isMobile && homeBaners}
          />
        </div>
      </div>
      <WatchNow data={videos.data} />
      <Cooperate />
      <AboutUs />
    </>
  );
};

export async function getServerSideProps() {
  let statusCode = '';

  const response = await fetch('https://webapi.toya.net.pl/v1/tvtoya/news', {
    method: 'GET',
    mode: 'cors',
    headers: { Authorization: 'Basic dHZ0b3lhOkFZVnVmVUFyR2Y3TWtFTnY=' },
  });
  statusCode = response.status;
  const data = await response.json();

  const videosReq = await fetch(
    `https://webapi.toya.net.pl/v1/tvtoya/onDemand?grouped=1&limit=9`,
    {
      method: 'GET',
      mode: 'cors',
      headers: { Authorization: 'Basic dHZ0b3lhOkFZVnVmVUFyR2Y3TWtFTnY=' },
    }
  );
  statusCode = videosReq.status;
  const videosData = await videosReq.json();
  if (response.status !== 200 || videosReq.status !== 200) {
    statusCode = true;
  }

  const bannersReq = await fetch(
    `https://webapi.toya.net.pl/v1/tvtoya/banners`,
    {
      method: 'GET',
      mode: 'cors',
      headers: { Authorization: 'Basic dHZ0b3lhOkFZVnVmVUFyR2Y3TWtFTnY=' },
    }
  );

  const banners = await bannersReq.json();

  return {
    props: {
      data,
      videos: videosData,
      statusCode,
      banersData: banners,
    },
  };
}

export default Home;

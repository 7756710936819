import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { Carousel as CarouselComp } from 'react-responsive-carousel';

export const FullscreenCarousel = styled((props) => (
  <CarouselComp {...props} sx={{ marginRight: '40px' }} />
))(() => ({
  '&': {
    paddingBottom: '0 !important',
  },
  '& .control-dots': {
    width: 'auto !important',
    left: '56%',
    transform: 'translateX(-50%)',
    bottom: '60px !important',
    '& .dot': {
      background: '#c4c4c4 !important',
    },
    '.dot.selected': {
      background: '#1D76BB !important',
    },
  },

  '@media(max-width:1365px)': {
    '& .control-dots': {
      left: '50%',
      bottom: '36px !important',
    },
  },
}));

export const PhotoContainer = styled((props) => <div {...props} />)(
  (props) => ({
    '&': {
      width: '100%',
      maxWidth: '1440px',
      height: '848px',
      height: '748px',
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 12.3%, #000000 90.99%),url(${
        props.news.image ? props.news.image : '/toyaCover.png'
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      margin: 'auto',
      '@media (min-width: 300px) and (max-width: 600px)': {
        height: '544px',
      },
      position: 'relative',
    },
  })
);

export const BackgroundWrapper = styled((props) => <div {...props} />)(
  (props) => ({
    '&': {
      width: '110vw',
      height: '105%',
      position: 'absolute',
      zIndex: '-1',
      left: '0',
      top: '0',

      '&::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100vh',
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 12.3%, #000000 90.99%),url(${
          props.image.image ? props.image.image : '/toyaCover.png'
        })`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        left: '0',
        filter: 'blur(20px)',
        zIndex: '1',
      },
    },
  })
);

export const HeaderButton = styled((props) => <Button {...props} />)(() => ({
  '&': {
    padding: '0 8px !important',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'white',
    margin: 'auto',
    ':hover': {
      color: '#A7D2F5',
    },
    ':active': {
      color: '#43A2EE',
    },
  },
}));

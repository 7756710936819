import styled from 'styled-components';

const StyledButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  text-transform: uppercase;
  color: #1d76bb;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  align-self: flex-end;
  display: flex;
  align-items: center;
  img {
    transform: rotate(-270deg);
    margin-left: 8px;
  }
`;

const ShowMoreButton = ({ click }) => {
  return (
    <StyledButton onClick={click}>
      Zobacz więcej <img src='/darkBlueArrow.svg' alt='down arrow' />
    </StyledButton>
  );
};

export default ShowMoreButton;

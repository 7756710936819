import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const SideMenuButton = styled((props) => <Button {...props} />)(() => ({
  '&': {
    padding: '0 8px !important',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#43A2EE',
    marginLeft: '247px',
  },
}));

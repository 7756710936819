import React from 'react';
import Carousel from '../Carousel';
import styles from '../../../styles/HeaderCarousel.module.scss';
import { useRouter } from 'next/router';
import { Chip } from '@mui/material';
import { SideMenuButton } from './HeaderCarousel.style';
import CarouselPhotoContainer from '../../CarouselPhotoContainer/CarouselPhotoContainer';

const HeaderCarousel = ({ dataForSlider = [], dataForSideNews = [] }) => {
  const router = useRouter();

  const goToArticle = (id) => {
    router.push(`/artykul/${id}`);
  };

  const goToArchive = (id) => {
    router.push('/archiwum/');
  };

  return (
    <div className={styles.carouselContainer}>
      <Carousel
        percent={100}
        center={true}
        autoPlay={true}
        infiniteLoop={true}
        sideShades={false}
        arrows={false}
        carouselVariant={'header'}
      >
        {dataForSlider.map((news) => (
          <CarouselPhotoContainer
            news={news}
            key={'carouselphotocontainer' + news}
            goToArticle={goToArticle}
          />
        ))}
      </Carousel>
      <div className={styles.sideNewsWrapper}>
        <div className={styles.sideNewsContainer}>
          <div className={styles.sideNews}>
            {dataForSideNews.map((news) => {
              return (
                <div className={styles.sideMenuLink} key={'data' + news.id}>
                  <Chip
                    label={news.category === 'regular' ? 'Wydarzenia' : 'Sport'}
                    className={`${styles.label} ${styles.labelBlue}`}
                    sx={{ marginRight: '8px' }}
                  />
                  <Chip
                    label={news.date}
                    className={`${styles.label} ${styles.labelDark}`}
                  />
                  <p
                    className={styles.linkText}
                    onClick={() => goToArticle(news.id)}
                  >
                    {news.title}
                    <img src='/whiteArrow.svg' />
                  </p>
                </div>
              );
            })}
            <SideMenuButton variant='text' onClick={goToArchive}>
              ZOBACZ WIĘCEJ
              <img src='/arrow.svg' />
            </SideMenuButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCarousel;
